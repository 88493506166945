@charset 'utf-8';

@import 'settings';
@import 'foundation';
//@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
//@include foundation-button;
//@include foundation-forms;
// @include foundation-range-input;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
//@include foundation-visibility-classes;
//@include foundation-float-classes;
// @include foundation-flex-classes;

//@include motion-ui-transitions;
//@include motion-ui-animations;

// !-------- Structure
// -----------------------------------------------------------------------------

body {
    height:100vh;
}

.page {
    //width:98vw;
    margin:0 auto;
    height:94vh;
    padding-top:0.5rem;
}

.song {
    //column-count:2;
    //column-gap:2rem;
    width:100%;
    display:inline-flex;
    flex-direction:column;
    flex-wrap:wrap;
    
    //max-height:94%;
    //height:100%;
    
    max-height:100%;
    font-size:1.18em;
    //overflow:hidden;
    
    
    p {
        //break-inside:avoid;
        //padding-right:1rem;
        margin:0 0.6rem 0.9rem 0.7rem;
        //max-width:47.5vw;
        line-height: 1.5;
        //width:100%;
        max-width:54vw;
    }
}

// !-------- Type
// -----------------------------------------------------------------------------

h1 {
    font-size:1rem;
    font-weight:600;
}

// !-------- Nav
// -----------------------------------------------------------------------------

nav {
    display:flex;
    justify-content: space-between;
    align-items:center;
    height:44px;
    padding:0 1vw;
    background:#eaeaea;
    //margin-bottom:0.5rem;
    
    a {
        padding:0 0.5rem;
        height:44px;
        line-height:44px;
        //background:#1779ba;
        //width:12.5%;
        //color:#ffffff;
        text-align: center;
    }
}







































